// color pellets
$grey: #00000073;
$theme: #ef3f3e;
$black: #000000;
$white: #ffffff;
$dark-grey: #8c8c8c;

.card-wrapper {
  padding: 32px;
  position: relative;
  color: $white;
  &::before {
    transform: rotate(-5deg) scale(1.5) translateY(-48px);
  }
}
.custom-card {
  border: 1.538px solid #f0f0f0;
  height: 100%;
  cursor: pointer;
  .ant-card-body {
    .ant-card-meta {
      .ant-card-meta-description {
        .ant-space-item {
          .ant-space-item {
            .text-title {
              font-size: 16px;
              font-weight: 700;
              margin-bottom: 0;
            }
            .text-description {
              color: $grey;
              line-height: 20px;
              font-size: 12px;
            }
            .title-wrapper {
              flex-direction: column;
              .logo-wrapper {
                .ant-space-item {
                  display: flex;
                }
              }
              .tags-wrapper {
                font-size: 10px;
                border-radius: 50px;
              }
            }
            .logo-img {
              width: 25px;
              height: 25px;
              object-fit: contain;
            }
          }
        }
      }
    }
  }
  .ant-card-cover {
    border-radius: 8px 8px 0 0;
    position: relative;
    padding-top: 53.48%;
    overflow: hidden;
    .ant-skeleton {
      width: 100%;
      .ant-skeleton-image {
        width: 100%;
        position: absolute;
        inset: 0;
        padding: 53.48%;
      }
    }
  }
  .float-lock {
    position: absolute;
    width: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: $dark-grey;
  }
  .float-star {
    font-size: 18px;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    background: #fff;
    width: 76px;
    height: 76px;
    display: flex;
    align-items: center;
    border: 1px solid #f0f0f0;
    justify-content: center;
    clip-path: polygon(0 0, 100% 0, 100% 0, 0% 100%);
    svg {
      position: absolute;
      left: 14px;
      top: 14px;
    }
  }
  .image {
    display: block;
    width: 100%;
    height: auto;
    backface-visibility: hidden;
    .card-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      inset: 0;
      position: absolute;
    }
  }
  .overlay {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      opacity: 0.8;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0.7) 100%
        ),
        #f1f3f4;
    }
    .request-text {
      position: absolute;
      width: auto;
      opacity: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
    }
  }
}
.private-card-cover {
  position: relative;
  .image {
    display: block;
    width: 100%;
    height: auto;
    backface-visibility: hidden;
    .card-image {
      height: 100%;
      width: 100%;
      object-fit: cover;
      inset: 0;
      position: absolute;
    }
  }
  .overlay {
    height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    &:before {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      inset: 0;
      opacity: 0.8;
      background: linear-gradient(
          0deg,
          rgba(0, 0, 0, 0.7) 0%,
          rgba(0, 0, 0, 0.7) 100%
        ),
        #f1f3f4;
    }
    .request-text {
      position: absolute;
      width: auto;
      opacity: 1;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: $white;
    }
  }
}
.featured-card {
  .ant-card-cover {
    border-radius: 8px 8px 0 0;
  }
}
.filter-tabs {
  .ant-tabs-ink-bar {
    background: $theme;
  }
  .ant-tabs-nav {
    margin-bottom: 50px;
    .ant-tabs-tab {
      .tabs-title {
        font-size: 16px;
        color: $black;
      }
      &.ant-tabs-tab-active {
        .tabs-title {
          font-size: 16px;
          color: $theme;
        }
      }
    }
  }
}
