@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;400&display=swap');

$primary: #ef3f3e;
$primary-hover: #f65454f6;

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
/* Default styles for the container */
.custom-container {
  width: 100%; /* Full width by default */
  padding: 16px 32px; /* Add some padding for content */
  box-sizing: border-box; /* Include padding and border in the width */
  margin: 0 auto; /* Center the container horizontally */
  @media (max-width: 576px) {
    padding: 15px 16px;
  }
}

.ant-btn-primary {
  background-color: $primary;
}
//important is used to overwrite inline css
.ant-btn-primary:hover {
  background-color: $primary-hover !important;
  border-color: $primary;
}

.ant-btn-default:hover {
  color: $primary !important;
  border-color: $primary !important;
}

.ant-btn-link {
  color: $primary !important;
  &:hover {
    color: $primary-hover !important;
  }
}

.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0;
}
