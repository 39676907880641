$primary: #ef3f3e;
$primary-hover: #f65454f6;

header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.25);
  .logo {
    cursor: pointer;
  }
}
