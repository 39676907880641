$off-white: #f5f5f5;
$off-black: #00000040;
$light-grey: #cecece;
$med-grey: #e2e2e2;
$med-black: #0000008a;
$black: #000000;

.login-wrapper {
  display: flex;
  height: 100vh;
  text-align: left;
  width: 100%;
  padding: 0px;
  margin: 0px;
  background: #fff;
  .cover-image {
    position: relative;
    width: 45%;
    .ant-image {
      height: 100%;
      width: 100%;
      img {
        display: block;
        object-fit: cover;
        height: 100%;
        width: 100%;
      }
    }
  }
  @media (max-width: 960px) {
    .cover-image {
      display: none;
    }
  }
  .login-form {
    width: 55%;
    display: flex;
    padding: 0px 80px;
    flex-direction: column;
    justify-content: center;
    gap: 24px;
    @media (max-width: 960px) {
      width: 100%;
    }
    .form-content {
      display: flex;
      gap: 32px;
      justify-content: center;
      flex-direction: column;
      max-width: 790px;
      h1 {
        font-size: 50px;
        font-weight: 400;
      }
      p {
        margin: 0;
        font-size: 18px;
        font-weight: 400;
      }
      .text-login {
        text-align: center;
        font-size: 18px;
      }
      .btn-login {
        display: flex;
        column-gap: 10px;
        align-items: center;
        justify-content: center;
        font-weight: 500;
        height: 60px;
        line-height: 23px;
        text-align: left;
        border: 1px solid $med-grey;
        border-radius: 10px;
        border: 1px solid $med-grey;
        width: 100%;
        span {
          color: $black;
        }
      }
    }
  }
}

.page-flex {
  display: inline-flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  .routes-wrapper {
    flex: 1 1;
  }
}
