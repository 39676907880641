$grey: #cdcdcd;

.project-wrapper {
  .collpsable-item {
    .header-items {
      .text-header {
        margin-bottom: 0;
        font-size: 38px;
        font-weight: 500;
        display: block;
        @media (max-width: 768px) {
          display: none;
        }
      }
      .back-arrow {
        font-size: 20px;
      }
      .logo-img {
        width: 35px;
        height: 35px;
        object-fit: contain;
      }
    }
    .ant-collapse-header {
      align-items: center;
      .ant-collapse-expand-icon {
        svg {
          height: 20px;
          width: 20px;
        }
      }
    }
    .tags-collection {
      .tags-wrapper {
        border-radius: 50px;
        font-size: 10px;
      }
    }
    .content-wrapper {
      width: 82%;
      @media (max-width: 768px) {
        width: 100%;
      }
      .text-title {
        display: none;
        @media (max-width: 768px) {
          display: block;
        }
      }
    }
  }
  .demo-section {
    padding: 64px 136px;
    @media (max-width: 1024px) {
      padding: 60px 112px;
    }
    @media (max-width: 576px) {
      padding: 32px 24px;
    }
    .demo-card {
      display: flex;
      justify-content: center;
      align-items: center;
      .card-wrapper {
        max-width: 545px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 60px;
        border: 1px solid $grey;
        border-radius: 10px;
        height: 100%;
        width: 100%;
        padding: 8px 0;
        .card-image {
          width: 72px;
          height: 72px;
          object-fit: cover;
        }
      }
    }
    .demo-video {
      display: flex;
      justify-content: center;
      align-items: center;
      .demo-video-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
        .project-iframe {
          width: 100%;
          height: 100%;
          border: 0;
          aspect-ratio: 16 / 9;
          border-radius: 10px;
        }
      }
    }
  }
  .website-iframe {
    .iframe {
      width: 100%;
      height: 100vh;
      border: 0;
    }
  }
}
.demo-modal-wrapper {
  //important is used to overwrite antd-modal element style.
  .demo-modal-container {
    width: 100% !important;
    margin-top: 4px;
    margin-bottom: 20px;
    .demo-iframe {
      width: 100%;
      height: 80vh;
      border: 0;
    }
  }
}

.modal-actions {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
}
